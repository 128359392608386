import { GetStaticProps, NextPage } from "next/types";
import {
  StoryblokContextData,
  getStoryBlokPageContext,
} from "./storyblok/StoryblokData";
import { DynamicPageWrap } from "./DynamicSBPage";
import { useRouter } from "next/router";
import { useEffect } from "react";

const DynamicPage: NextPage<StoryblokContextData> = (
  contextData: StoryblokContextData
) => {
  return <DynamicPageWrap {...contextData} />;
  // return <DynamicPageWrap {...contextData, error:{...contextData.error, is500:true}} />;
};

const getStaticProps: GetStaticProps = async (props: any) => {
  // console.log("getStaticProp2s", { p: props.params });
  if (props?.params?.slug[0] === "insights") {
    props.params.slug[0] = "blogs";
  }
  const contextData: StoryblokContextData = await getStoryBlokPageContext(
    props
  );

  return {
    props: {
      contextData,
    },
    // notFound: true,
    // revalidate: 3600, // revalidate every hour
    revalidate: 1, // revalidate every hour
  };
};

export default DynamicPage;
export { getStaticProps };
